import { array } from "fast-web-kit";
import { can, isAdmin, user } from "../helpers";

export const mainMenuTitle = {
    title: "MAIN",
    visible: can('create_school') || can('list_school') || can('view_dashboard')
};
// module title be "MAIN"
export const mainMenus = [
    // DASHBOARD MENU
    {
        title: 'Dashboard',
        icon: 'dashboard',
        link: '/dashboard',
        visible: can('view_dashboard'),
        hasSubMenu: false
    },

    // SCHOOL MENU
    {
        title: 'School',
        icon: 'store',
        link: '#',
        subMenu: [
            {
                title: 'New school',
                link: '/create-school',
                visible: can('create_school'),
            },

            {
                title: 'List schools',
                link: '/list-school',
                visible: can('list_school'),
            },

            // {
            //     title: 'School Activity',
            //     link: '/list-activity',
            //     visible: can('list_activity'),
            // },

            // {
            //     title: 'View school',
            //     link: '/view-school',
            //     visible: can('view_school'),
            // },
            // {
            //     title: 'configurations',
            //     link: '/school-config',
            //     visible: can('config_school') && !(isAdmin && user.role === null),
            // },
        ],

        visible: can('create_school') || can('list_school'),
        hasSubMenu: true
    },
]

export const academicMenuTitle = {
    title: "ACADEMIC",
    visible: can('publish_result') || can('create_academic_year') || can('list_academic_year') || can('create_term') || can('list_term') || can('create_timetable') || can('list_timetable') || can('create_stream') || can('list_stream') || can('create_classroom') || can('list_classroom') || can('create_subject') || can('list_subject') || can('create_character') || can('list_character') || can('create_grade') || can('list_grade') || can('create_mark') || can('list_mark') || can('create_exam') || can('list_exam') || can('create_exam_info') || can('list_exam_info') || can('view_exam_info') || can('add_comment') || can('create_result') || can('list_result') || can('create_class_subject') || can('list_class_subject') || can('create_subject_teacher') || can('list_subject_teacher') || can('create_character_category') || can('list_character_category') || can('view_necta_result') || can('create_scheme_of_work') || can('list_scheme_of_work') || can('create_topic') || can('list_topic') || can('create_reference') || can('list_reference') || can('create_lesson_plan') || can('list_lesson_plan') || can('create_log_book') || can('list_log_book')
};

export const academicMenus = [
    // level menu
    {
        title: 'level',
        icon: 'signal_cellular_alt',
        link: '#',
        subMenu: [
            {
                title: 'New level',
                link: '/create-level',
                visible: can('create_level'),
            },

            {
                title: 'List levels',
                link: '/list-level',
                visible: can('list_level'),
            },

        ],

        visible: can('create_level') || can('list_level'),
        hasSubMenu: true
    },

    // academic menu
    {
        title: 'Academic year',
        icon: 'watch_later',
        link: '#',
        subMenu: [
            {
                title: 'New academic year',
                link: '/create-academic-year',
                visible: can('create_academic_year'),
            },

            {
                title: 'List academic years',
                link: '/list-academic-year',
                visible: can('list_academic_year'),
            },

        ],

        visible: can('create_academic_year') || can('list_academic_year'),
        hasSubMenu: true
    },

    // TERM MENU
    {
        title: 'Term',
        icon: 'date_range',
        link: '#',
        subMenu: [
            {
                title: 'New Term',
                link: '/create-term',
                visible: can('create_term'),
            },
            {
                title: 'List terms',
                link: '/list-term',
                visible: can('list_term'),
            },
        ],

        hasSubMenu: true,
        visible: can('create_term') || can('list_term'),
    },

    // CLASSROOM MENU
    {
        title: 'Class',
        icon: 'gite',
        link: '#',
        subMenu: [
            {
                title: 'New Class',
                link: '/create-class',
                visible: can('create_class'),
            },

            {
                title: 'List classes',
                link: '/list-class',
                visible: can('list_class'),
            },

        ],



        visible: can('create_class') || can('list_class'),
        hasSubMenu: true
    },

    // STREAM MENU
    {
        title: 'Stream',
        icon: 'text_format',
        link: '#',
        subMenu: [
            {
                title: 'New stream',
                link: '/create-stream',
                visible: can('create_stream'),
            },
            {
                title: 'List streams',
                link: '/list-stream',
                visible: can('list_stream'),
            },
        ],

        hasSubMenu: true,
        visible: can('create_stream') || can('list_stream'),
    },

    // SUBJECT MENU
    {
        title: 'Subject',
        icon: 'auto_stories',
        link: '#',
        subMenu: [
            {
                title: 'New Subject',
                link: '/create-subject',
                visible: can('create_subject'),
            },
            {
                title: 'List subjects',
                link: '/list-subject',
                visible: can('list_subject'),
            },
            {
                title: 'New subject teacher',
                link: '/create-teacher-subject',
                visible: can('create_subject_teacher'),
            },
            {
                title: 'List subject teacher',
                link: '/list-teacher-subject',
                visible: can('list_subject_teacher'),
            },
            {
                title: 'New class subject',
                link: '/create-class-subject',
                visible: can('create_class_subject'),
            },
            {
                title: 'List class subject',
                link: '/list-class-subject',
                visible: can('list_class_subject'),
            },
        ],

        visible: can('create_subject') || can('list_subject') || can('create_subject_teacher') || can('create_class_subject') || can('list_class_subject') || can('list_subject_teacher'),
        hasSubMenu: true
    },

    // GRADES MENU
    {
        title: 'Grade',
        icon: 'grading',
        link: '#',
        subMenu: [
            {
                title: 'New Grade',
                link: '/create-grade',
                visible: can('create_grade'),
            },

            {
                title: 'List grades',
                link: '/list-grade',
                visible: can('list_grade'),
            },


        ],
        visible: can('create_grade') || can('list_grade'),
        hasSubMenu: true
    },

    // EXAMS MENU
    {
        title: 'Exam',
        icon: 'article',
        link: '#',
        subMenu: [
            {
                title: 'New Exam',
                link: '/create-exam',
                visible: can('create_exam'),
            },

            {
                title: 'List Exams',
                link: '/list-exam',
                visible: can('list_exam'),
            },

            {
                title: 'Exam info',
                link: '/create-info-exam',
                visible: can('create_exam_info'),
            },
            {
                title: 'List Exam info',
                link: '/list-info-exam',
                visible: can('create_exam_info'),
            },
        ],

        visible: can('create_exam') || can('list_exam') || can('create_exam_info') || can('list_exam_info'),
        hasSubMenu: true
    },

    // MARK MENU
    {
        title: 'Mark',
        icon: 'fact_check',
        link: '#',
        subMenu: [
            {
                title: 'New Mark',
                link: '/create-mark',
                visible: can('create_mark'),
            },

            {
                title: 'List marks',
                link: '/list-mark',
                visible: can('list_mark'),
            },


        ],
        visible: can('create_mark') || can('list_mark'),
        hasSubMenu: true
    },

    // CHARACTER MENU
    {
        title: 'Character',
        icon: 'closed_caption',
        link: '#',
        subMenu: [
            {
                title: 'New Character',
                link: '/create-character',
                visible: can('create_character'),
            },

            {
                title: 'List Characters',
                link: '/list-character',
                visible: can('list_character'),
            },

            {
                title: 'New Category',
                link: '/create-character-category',
                visible: can('create_character_category'),
            },

            {
                title: 'List Categories',
                link: '/list-character-category',
                visible: can('list_character_category'),
            },
        ],

        visible: can('create_character') || can('list_character') || can('create_character_category') || can('list_character_category'),
        hasSubMenu: true
    },

    // TIMETABLE
    {
        title: 'Timetable',
        icon: 'work_history',
        link: '#',
        subMenu: [
            {
                title: 'New Timetable',
                link: '/create-timetable',
                visible: can('create_timetable'),
            },

            {
                title: 'List timetables',
                link: '/list-timetable',
                visible: can('list_timetable'),
            },


        ],
        visible: can('create_timetable') || can('list_timetable'),
        hasSubMenu: true
    },

    // teaching tools
    {
        title: 'teaching tools',
        icon: 'architecture',
        link: '#',
        subMenu: [
            {
                title: 'New Scheme of work',
                link: '/create-scheme-of-work',
                visible: can('create_scheme_of_work'),
            },
            {
                title: 'list Scheme of work',
                link: '/list-scheme-of-work',
                visible: can('list_scheme_of_work'),
            },
            {
                title: 'New topic',
                link: '/create-topic',
                visible: can('create_topic'),
            },
            {
                title: 'list topics',
                link: '/list-topic',
                visible: can('list_topic'),
            },
            {
                title: 'New reference',
                link: '/create-reference',
                visible: can('create_reference'),
            },
            {
                title: 'list references',
                link: '/list-reference',
                visible: can('list_reference'),
            },
            {
                title: 'New Lesson plan',
                link: '/create-lesson-plan',
                visible: can('create_lesson_plan'),
            },
            {
                title: 'list Lesson plans',
                link: '/list-lesson-plan',
                visible: can('list_lesson_plan'),
            },
            {
                title: 'New Log book',
                link: '/create-log-book',
                visible: can('create_log_book'),
            },
            {
                title: 'list Log books',
                link: '/list-log-book',
                visible: can('list_log_book'),
            },
        ],

        visible: can('create_scheme_of_work') || can('list_scheme_of_work') || can('create_topic') || can('list_topic') || can('create_reference') || can('list_reference') || can('create_lesson_plan') || can('list_lesson_plan') || can('create_log_book') || can('list_log_book'),
        hasSubMenu: true
    },

    // RESULT MENU
    {
        title: 'result',
        icon: 'receipt',
        link: '#',
        subMenu: [
            {
                title: 'Necta',
                link: '/view-necta-result',
                visible: can('view_necta_result'),
            },
            {
                title: 'New result',
                link: '/create-result',
                visible: can('create_result'),
            },
            {
                title: 'List results',
                link: '/list-result',
                visible: can('list_result'),
            },
            {
                title: 'Publish result',
                link: '/publish-result',
                visible: can('publish_result'),
            },
            {
                title: "Comment",
                link: "/comment-per-student",
                visible: can("add_comment")
            }
        ],

        visible: can('publish_result') || can('create_result') || can('list_result') || can('view_necta_result') || can('add_comment'),
        hasSubMenu: true
    },

]

export const administrationMenuTitle = {
    title: "ADMINISTRATION",
    visible: can('create_user') || can('list_user') || can('create_student') || can('list_student') || can('create_parent') || can('list_parent') || can('create_teacher') || can('list_teacher') || can('create_staff') || can('list_staff') || can('create_sponsor') || can('list_sponsor') || can('create_role') || can('list_role')
};

export const administrationMenus = [
    // ROLE MENU
    {
        title: 'Role',
        icon: 'task',
        link: '#',
        subMenu: [
            {
                title: 'New Role',
                link: '/create-role',
                visible: can('create_role'),
            },

            {
                title: 'List Roles',
                link: '/list-role',
                visible: can('list_role'),
            },

        ],

        visible: can('create_role') || can("list_role"),
        hasSubMenu: true
    },
    // USER MENU
    {
        title: 'User',
        icon: 'manage_accounts',
        link: '#',
        subMenu: [
            {
                title: 'New User',
                link: '/create-user',
                visible: can('create_user'),
            },
            {
                title: 'List Users',
                link: '/list-user',
                visible: can('list_user'),
            }
        ],

        visible: can('create_user') || can('list_user'),
        hasSubMenu: true
    },
    // STUDENT MENU
    {
        title: 'Student',
        icon: 'local_library',
        link: '#',
        subMenu: [
            {
                title: 'New Student',
                link: '/create-student',
                visible: can('create_student'),
            },

            {
                title: 'List students',
                link: '/list-student',
                visible: can('list_student'),
            },


        ],



        visible: can('create_student') || can('list_student'),
        hasSubMenu: true
    },

    // PARENT MENU
    {
        title: 'parent',
        icon: 'diversity_3',
        link: '#',
        subMenu: [
            {
                title: 'New parent',
                link: '/create-parent',
                visible: can('create_parent'),
            },

            {
                title: 'List parents',
                link: '/list-parent',
                visible: can('list_parent'),
            },

        ],
        visible: can('create_parent') || can('list_parent'),
        hasSubMenu: true
    },

    // TEACHER MENU
    {
        title: 'Teacher',
        icon: 'person',
        link: '#',
        subMenu: [
            {
                title: 'New Teacher',
                link: '/create-teacher',
                visible: can('create_teacher'),
            },

            {
                title: 'List Teachers',
                link: '/list-teacher',
                visible: can('list_teacher'),
            },
        ],

        visible: can('create_teacher') || can('list_teacher'),
        hasSubMenu: true
    },

    // STAFF MENU
    {
        title: 'Non Staff',
        icon: 'people',
        link: '#',
        subMenu: [
            {
                title: 'New Staff',
                link: '/create-staff',
                visible: can('create_staff'),
            },

            {
                title: 'List Staffs',
                link: '/list-staff',
                visible: can('list_staff'),
            },
        ],

        visible: can('create_staff') || can('list_staff'),
        hasSubMenu: true
    },

    {
        title: 'Sponsor',
        icon: 'person_add',
        link: '#',
        subMenu: [
            {
                title: 'New Sponsor',
                link: '/create-sponsor',
                visible: can('create_sponsor'),
            },

            {
                title: 'List Sponsors',
                link: '/list-sponsor',
                visible: can('list_sponsor'),
            },
        ],

        visible: can('create_sponsor') || can('list_sponsor'),
        hasSubMenu: true
    },

]

export const operationMenuTitle = {
    title: "OPERATION",
    visible: can('create_id') || can('list_id') || can('view_student_analysis') || can('view_parent_analysis') || can('create_attendance') || can('list_attendance') || can('create_hostel') || can('list_hostel') || can('create_library') || can('list_library') || can('create_transport') || can('list_transport') || can('create_allowance') || can('list_allowance') || can('create_salary') || can('list_salary') || can('create_deduction') || can('list_deduction') || can('create_pension') || can('list_pension')
};

export const operationMenus = [

    // ATTENDANCE MENU
    {
        title: 'Attendance',
        icon: 'pending_actions',
        link: '#',
        subMenu: [
            {
                title: 'New attendance',
                link: '/create-attendance',
                visible: can('create_attendance'),
            },

            {
                title: 'List attendances',
                link: '/list-attendance',
                visible: can('list_attendance'),
            },
        ],

        visible: can('create_attendance') || can('list_attendance'),
        hasSubMenu: true
    },

    // transport
    {
        title: 'Transport',
        icon: 'directions_bus',
        link: '#',
        subMenu: [
            {
                title: 'New transport',
                link: '/create-transport',
                visible: can('create_transport'),
            },

            {
                title: 'List transports',
                link: '/list-transport',
                visible: can('list_transport'),
            },
        ],

        visible: can('create_transport') || can('list_transport'),
        hasSubMenu: true
    },

    // id
    {
        title: 'ID Card',
        link: '/print-id',
        hasSubMenu: false,
        visible: can('print_id'),
        icon: 'branding_watermark',
    },

    // HOSTEL MENU
    {
        title: 'Hostel',
        icon: 'other_houses',
        link: '#',
        subMenu: [
            {
                title: 'New allocation',
                link: '/create-allocation',
                visible: can('create_hostel_allocation'),
            },

            {
                title: 'List allocations',
                link: '/list-allocation',
                visible: can('list_hostel_allocation'),
            },
            {
                title: 'New room',
                link: '/create-room',
                visible: can('create_room'),
            },

            {
                title: 'List rooms',
                link: '/list-room',
                visible: can('list_room'),
            },

            {
                title: 'New hostel',
                link: '/create-hostel',
                visible: can('create_hostel'),
            },

            {
                title: 'List hostels',
                link: '/list-hostel',
                visible: can('list_hostel'),
            },

        ],

        visible: can('create_hostel_allocation') || can('list_hostel_allocation') || can('create_room') || can('list_room') || can('create_hostel') || can('list_hostel'),
        hasSubMenu: true
    },

    // LEAVE MENU
    {
        title: 'Leave',
        icon: 'work_off',
        link: '#',
        subMenu: [
            {
                title: 'Apply leave',
                link: '/apply-leave',
                visible: can('apply-leave'),
            },
            {
                title: 'New Leave type',
                link: '/create-leave-type',
                visible: can('create_leave_type'),
            },

            {
                title: 'List Leave types',
                link: '/leaves-type',
                visible: can('list_leave_type'),
            },
            {
                title: 'List Leaves',
                link: '/list-leave',
                visible: can('list_leave'),
            },
        ],

        visible: can('create_leave_type') || can('create_leave') || can('apply-leave') || can('list_leave_type') || can('list_leave'),
        hasSubMenu: true
    },

    // LIBRARY
    {
        title: 'Library',
        icon: 'menu_book',
        link: '#',
        subMenu: [
            {
                title: 'New resource',
                link: '/create-library-resource',
                visible: can('create_library_resource'),
            },
            {
                title: 'List resources',
                link: '/list-library-resource',
                visible: can('list_library_resource'),
            },
            {
                title: 'Manage resources',
                link: '/manage-library-resource',
                visible: can('manage_library_resource'),
            }
        ],

        visible: can('create_library_resource') || can('list_library_resource') || can("manage_library_resource"),
        hasSubMenu: true
    },

    // analysis
    {
        title: 'Analysis',
        icon: 'auto_graph',
        link: '/analysis',
        hasSubMenu: false,
        visible: can('view_student_analysis') || can('view_parent_analysis'),
    },
]

export const accountMenuTitle = {
    title: "ACCOUNT",
    visible: can('create_liability') || can('list_liability') || can('create_asset') || can('list_asset') || can('create_installment') || can('list_installment') || can('list_fee_structure') || can('create_fee_structure') || can('list_fees') || can('create_opening_balance') || can('define_fees') || can('create_fee_discount') || can('unsubscribe_fees') || can('create_invoice') || can('list_invoice') || can('create_payment') || can('list_payment') || can("view_journal_entry") || can("view_chart_of_accounts") || can("create_account_code") || can("list_account_code") || can('create_class_account') || can('list_class_account') || can('create_account_group') || can('list_account_group') || can('create_transaction') || can('list_transaction') || can('create_account') || can('list_account') || can("view_trial_balance") || can("/view_cash_flow") || can("view_profit_and_loss") || can("/view_balance_sheet")
};

export const accountMenus = [
    // INSTALLMENT MENU
    {
        title: 'Installment',
        icon: 'download_done',
        link: '#',
        subMenu: [
            {
                title: 'New installment',
                link: '/create-installment',
                visible: can('create_installment'),
            },

            {
                title: 'List installments',
                link: '/list-installment',
                visible: can('list_installment'),
            },


        ],
        visible: can('create_installment') || can('list_installment'),
        hasSubMenu: true
    },

    // FEE MENU
    {
        title: 'Fees',
        icon: 'payments',
        link: '#',
        subMenu: [
            {
                title: 'Fee structure',
                link: '/structure-fees',
                visible: can('create_fee_structure'),
            },
            {
                title: 'List fee structure',
                link: '/list-structure-fees',
                visible: can('list_fee_structure'),
            },
            {
                title: 'Opening balance',
                link: '/opening-balance',
                visible: can('create_opening_balance'),
            },
            {
                title: 'Define',
                link: '/define-fees',
                visible: can('define_fees'),
            },
            {
                title: 'List fees',
                link: '/list-fees',
                visible: can('list_fees'),
            },

            {
                title: 'Discount',
                link: '/create-fee-discount',
                visible: can('create_fee_discount'),
            },

            {
                title: 'Unsubscribe student',
                link: '/unsubscribe-fees',
                visible: can('unsubscribe_fees'),
            },

        ],
        visible: can('create_fee_structure') || can('list_fee_structure') || can('list_fees') || can('create_opening_balance') || can('define_fees') || can('create_fee_discount') || can('unsubscribe_fees'),
        hasSubMenu: true
    },

    // INVOICES
    {
        title: 'Invoice',
        icon: 'payment',
        link: '#',
        subMenu: [
            {
                title: 'New invoice',
                link: '/create-invoice',
                visible: can('create_invoice'),
            },
            {
                title: 'List invoices',
                link: '/list-invoice',
                visible: can('list_invoice'),
            },
            {
                title: 'New Payment',
                link: '/pay-invoice',
                visible: can('create_payment'),
            },
            {
                title: 'List Payments',
                link: '/history-invoice',
                visible: can('list_payment'),
            },
        ],

        visible: can('create_invoice') || can('list_invoice') || can('create_payment') || can('list_payment'),
        hasSubMenu: true
    },


    // Payroll
    {
        title: 'Payroll',
        icon: 'money',
        link: '#',
        subMenu: [
            {
                title: 'New allowance',
                link: '/create-allowance',
                visible: can('create_allowance'),
            },

            {
                title: 'List allowances',
                link: '/list-allowance',
                visible: can('list_allowance'),
            },
            {
                title: 'New deduction',
                link: '/create-deduction',
                visible: can('create_deduction'),
            },

            {
                title: 'List deductions',
                link: '/list-deduction',
                visible: can('list_deduction'),
            },
            {
                title: 'New pension',
                link: '/create-pension',
                visible: can('create_pension'),
            },

            {
                title: 'List pensions',
                link: '/list-pension',
                visible: can('list_pension'),
            },
            {
                title: 'New salary',
                link: '/create-salary',
                visible: can('create_salary'),
            },

            {
                title: 'List salaries',
                link: '/list-salary',
                visible: can('list_salary'),
            }

        ],
        visible: can('create_allowance') || can('list_allowance') || can('create_salary') || can('list_salary') || can('create_deduction') || can('list_deduction') || can('create_pension') || can('list_pension'),
        hasSubMenu: true
    },


    // GL MENU
    {
        title: 'General ledger',
        icon: 'build',
        link: "#",
        subMenu: [
            {
                title: "Chart of accounts",
                link: "/view-chart-of-account",
                visible: can("view_chart_of_accounts")
            },
            {
                title: "New account group",
                link: "/create-account-group",
                visible: can("create_account_group")
            },
            {
                title: "List account groups",
                link: "/list-account-group",
                visible: can("list_account_group")
            },
            {
                title: "Journal Entry",
                link: "/view-journal-entry",
                visible: can("view_journal_entry")
            },

        ],
        visible: can("view_journal_entry") || can("view_chart_of_accounts") || can('create_account_group') || can('list_account_group'),
        hasSubMenu: true
    },

    // TRANSACTION MENU
    {
        title: "Transaction",
        icon: "paid",
        link: '#',
        subMenu: [
            {
                title: 'New asset',
                link: '/create-asset',
                visible: can('create_asset'),
            },
            {
                title: 'List assets',
                link: '/list-asset',
                visible: can('list_asset'),
            },
            {
                title: 'New liability',
                link: '/create-liability',
                visible: can('create_liability'),
            },
            {
                title: 'List liabilities',
                link: '/list-liability',
                visible: can('list_liability'),
            },
            {
                title: 'New account',
                link: '/create-bank-account',
                visible: can('create_account'),
            },
            {
                title: 'List accounts',
                link: '/list-bank-account',
                visible: can('list_account'),
            },
            {
                title: 'New transaction',
                link: '/create-transaction',
                visible: can('create_transaction'),
            },
            {
                title: 'List transactions',
                link: '/list-transaction',
                visible: can('list_transaction'),
            },
            // {
            //     title: "Bank reconciliation",
            //     link: "/bank-reconciliation",
            //     visible: can("/view_bank_reconciliation")
            // },

        ],
        visible: can('create_liability') || can('list_liability') || can('create_asset') || can('list_asset') || can('create_transaction') || can('list_transaction') || can('create_account') || can('list_account'),
        hasSubMenu: true
    },

    // INVENTORY MENU
    {
        title: "Inventory",
        icon: "inventory_2",
        link: '#',
        subMenu: [
            {
                title: 'New warehouse',
                link: '/create-warehouse',
                visible: can('create_warehouse'),
            },
            {
                title: 'List warehouses',
                link: '/list-warehouse',
                visible: can('list_warehouse'),
            },
            {
                title: 'New vendor',
                link: '/create-vendor',
                visible: can('create_vendor'),
            },
            {
                title: 'List vendors',
                link: '/list-vendor',
                visible: can('list_vendor'),
            },
            {
                title: 'New item',
                link: '/create-item',
                visible: can('create_item'),
            },
            {
                title: 'List items',
                link: '/list-item',
                visible: can('list_item'),
            },
            {
                title: 'New purchases',
                link: '/create-purchases',
                visible: can('create_purchases'),
            },
            {
                title: 'List purchases',
                link: '/list-purchases',
                visible: can('list_purchases'),
            },
            {
                title: 'New usage',
                link: '/create-usage',
                visible: can('create_usage'),
            },
            {
                title: 'List usage',
                link: '/list-usage',
                visible: can('list_usage'),
            },

        ],
        visible: can('create_vendor') || can('list_vendor') || can('create_warehouse') || can('list_warehouse'),
        hasSubMenu: true
    },

    // FINANCIAL STATEMENTS MENU
    {
        title: "Financial statements",
        icon: "poll",
        link: '/financial-statements',
        visible: can("view_trial_balance") || can("/view_cash_flow") || can("view_profit_and_loss") || can("/view_balance_sheet"),
        hasSubMenu: false
    },
]

export const communicationMenuTitle = {
    title: "COMMUNICATION",
    visible: can('create_schedule') || can('list_schedule') || can('create_message') || can('list_message') || can('create_news') || can('list_news')
};

// module title be "COMMUNICATION"
export const communicationMenus = [
    // MESSAGE 
    {
        title: 'message',
        icon: 'mail',
        link: '#',
        subMenu: [
            {
                title: 'New message',
                link: '/create-message',
                visible: can('create_message'),
            },

            {
                title: 'List messages',
                link: '/list-message',
                visible: can('list_message'),
            },
        ],

        visible: can('create_message') || can('list_message'),
        hasSubMenu: true
    },

    {
        title: 'schedule',
        icon: 'schedule',
        link: '#',
        subMenu: [
            {
                title: 'New schedule',
                link: '/create-schedule',
                visible: can('create_schedule'),
            },

            {
                title: 'List schedules',
                link: '/list-schedule',
                visible: can('list_schedule'),
            },
        ],

        visible: can('create_schedule') || can('list_schedule'),
        hasSubMenu: true
    },

    // NEWS MENU
    {
        title: 'News',
        icon: 'newspaper',
        link: '#',
        subMenu: [
            {
                title: 'New news',
                link: '/create-news',
                visible: can('create_news'),
            },

            {
                title: 'List News',
                link: '/list-news',
                visible: can('list_news'),
            },
        ],

        visible: can('create_news') || can('list_news'),
        hasSubMenu: true
    },

]


export const digitalMenuTitle = {
    title: "E-LEARNING",
    visible: can('view_lab') || can("view_books") || can("upload_notes") || can("list_notes")
};

export const digitalMenus = [
    {
        title: 'Lab',
        link: '/lab',
        icon: 'biotech',
        visible: can('view_lab'),
        hasSubMenu: false
    },
    // {
    //     title: 'file',
    //     link: '/file',
    //     icon: 'biotech',
    //     visible: can('view_file'),
    //     hasSubMenu: false
    // },
    {
        icon: 'videocam',
        hasSubMenu: false,
        title: 'Live studies',
        link: '/live studies',
        visible: can('view_live_studies'),
    },
    {
        icon: 'description',
        title: 'Class notes',
        link: '#',
        subMenu: [
            {
                title: 'New notes',
                link: '/create-notes',
                visible: can('upload_notes'),
            },

            {
                title: 'List notes',
                link: '/list-notes',
                visible: can('list_notes'),
            },
        ],

        visible: can('upload_notes') || can('list_notes'),
        hasSubMenu: true
    },
    {
        title: 'Online exam',
        link: '/Online exam',
        icon: 'wifi',
        visible: can('view_online_exam'),
        hasSubMenu: false
    },
    {
        title: 'Home packages',
        link: '/Home packages',
        icon: 'backpack',
        visible: can('view_home_packages'),
        hasSubMenu: false
    },
    {
        title: 'Books',
        link: '/book',
        icon: 'auto_stories',
        visible: can('view_books'),
        hasSubMenu: false
    },
]

export const otherMenus = [
    {
        title: 'Settings',
        icon: 'settings',
        link: '/system-setting',
        visible: can('config_school') && !(isAdmin && array.isEmpty(user.role)),
        hasSubMenu: false,
    },

    // PROFILE MENU
    {
        title: 'profile',
        icon: 'account_circle',
        link: '#',
        subMenu: [
            {
                title: 'view profile',
                link: '/view-profile',
                visible: true,
            },

            {
                title: 'edit profile',
                link: '/edit-profile',
                visible: can('edit_profile'),
            },

            {
                title: 'change password',
                link: '/change-password',
                visible: true,
            },

        ],
        visible: true,
        hasSubMenu: true
    },

]